<template>
  <div id="section3" class="section-w3ls">
    <input type="radio" name="sections" id="option3">
    <label for="option3" class="icon-left-w3pvt">
      <span class="fa fa-lock" aria-hidden="true"></span>忘记密码
    </label>
    <article>
      <el-form style="text-align: center;" :model="resetForm" ref="reset" :rules="rules">
        <el-form-item>
          <h3 class="legend last">重置密码</h3>
        </el-form-item>
        <el-form-item prop="phoneNumber">
          <el-input auto-complete='new-password' v-model="resetForm.phoneNumber" prefix-icon="el-icon-mobile-phone" placeholder="手机号"></el-input>
        </el-form-item>
        <el-form-item prop="verification">
          <div style="display: flex;justify-content: space-between;">
            <el-input auto-complete='new-password' v-model="resetForm.verification" prefix-icon="el-icon-chat-round" placeholder="验证码"></el-input>
            <valid style="margin-left: 10px;" :phone='this.resetForm.phoneNumber' :event='event'></valid>
          </div>
        </el-form-item>
        <el-form-item prop="newPassword">
          <el-input auto-complete='new-password' v-model="resetForm.newPassword" prefix-icon="el-icon-key" show-password placeholder="新密码">
          </el-input>
        </el-form-item>
        <el-form-item prop="confirmPwd">
          <el-input auto-complete='new-password' v-model="resetForm.confirmPwd" prefix-icon="el-icon-refresh-left" show-password placeholder="确认密码">
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-checkbox v-model="resetForm.isCheck">我已阅读并同意相关<a href="/service" class="blue">服务条款</a>和<a href="/policy"
              class="blue">隐私政策</a></el-checkbox>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" style="width: 100%;" :disabled="btnState==false" @click="toReset"> 重 置 </el-button>
        </el-form-item>
      </el-form>
    </article>
  </div>
</template>

<script>
import valid from '@/components/getCode.vue'
export default {
  components: { valid },
  data () {
    const validatePass = (rule, value, callback) => {
      if (this.resetForm.confirmPwd !== this.resetForm.newPassword) {
        callback(new Error('两次输入密码不一致!'))
      }
      callback()
    }
    return {
      event: 'resetpwd',
      resetForm: {
        phoneNumber: '',
        verification: '',
        newPassword: '',
        confirmPwd: '',
        isCheck: false
      },
      rules: {
        phoneNumber: [
          { required: true, message: '手机号不可为空', trigger: 'blur' },
          { pattern: /^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/, message: '手机号格式不正确', trigger: 'blur' }
        ],
        verification: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { len: 4, message: '验证码格式错误', trigger: 'blur' }
        ],
        newPassword: [
          { required: true, message: '请输入登录密码', trigger: 'blur' },
          { min: 6, max: 18, message: '长度在6到18个字符', trigger: 'blur' }
        ],
        confirmPwd: [
          { required: true, message: '请输入登录密码', trigger: 'blur' },
          { min: 6, max: 18, message: '长度在6到18个字符', trigger: 'blur' },
          { validator: validatePass, message: '两次输入密码不一致', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    // 都不为空时btnState==true，改变按钮禁用状态
    btnState () {
      return this.resetForm.phoneNumber !== '' && this.resetForm.verification !== '' && this.resetForm.newPassword !== '' && this.resetForm.confirmPwd !== '' && this.resetForm.isCheck
    }
  },
  methods: {
    toReset () {
      this.$refs.reset.validate(valid => {
        if (valid) {
          let data = {
            type: 'mobile',
            mobile: this.resetForm.phoneNumber,
            newpassword: this.resetForm.confirmPwd,
            captcha: this.resetForm.verification
          }
          this.$apiFun.resetpwd(data).then(res => {
            // console.log(res)
            if (res.code == 1) {
              this.$message.success('密码重置成功')
              this.$emit('closeDialog')
            } else {
              this.$message.info(res.msg)
            }
          })
        } else {
          this.$message.error('密码重置失败')
          return false
        }
      })
    }
  }
}
</script>

<style scoped>
  .blue {
    color: #55aaff;
  }
  ::v-deep .el-checkbox__label {
    white-space: pre-line;
    word-wrap: break-word;
  }
</style>
