<template>
  <div id="section1" class="section-w3ls">
    <input type="radio" name="sections" id="option1" :checked='isLogin == 0'>
    <label for="option1" class="icon-left-w3pvt">
      <span class="fa fa-user-circle" aria-hidden="true"></span>登录
    </label>
    <article>
      <el-form style="text-align: center;" :model="loginForm" :rules="rules" ref="login">
        <el-form-item>
          <h3 class="legend">在此登录</h3>
        </el-form-item>
        <el-form-item prop="account">
          <el-input auto-complete='new-password' v-model="loginForm.account" prefix-icon='el-icon-user'
            placeholder="账号 (邮箱,手机号或用户名)"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input auto-complete='new-password' v-model="loginForm.password" prefix-icon='el-icon-unlock'
            placeholder="密码" type="password"></el-input>
        </el-form-item>
        <el-form-item prop="verification">
          <div style="display: flex;justify-content: space-between;">
            <el-input auto-complete='new-password' v-model="loginForm.verification" prefix-icon='el-icon-folder-checked'
              placeholder='验证码'></el-input>
            <valid style="margin-left: 10px;" :changeCode.sync='identifyCode'></valid>
          </div>
        </el-form-item>
        <el-checkbox v-model="remember"><span class="blue">记住密码</span></el-checkbox>
        <el-form-item>
          <el-checkbox v-model="loginForm.check">我已阅读并同意相关<a href="/service" class="blue">服务协议</a></el-checkbox>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="toAdmin()" :disabled="btnState == false" v-preventReClick>登 录</el-button>
          <el-button @click="resetForm()" v-preventReClick>重 置</el-button>
        </el-form-item>
      </el-form>
    </article>
  </div>
</template>

<script>
import valid from '@/components/dialog/valid'
// 引入加密方式
const Base64 = require('js-base64').Base64

export default {
  components: { valid },
  data () {
    // 验证手机号/邮箱格式
    const validatePhoneMail = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('手机号/邮箱不为空'))
      } else {
        // 内容不为空
        if (value !== '') {
          // 判断是否符合手机号正则
          const reg = /^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/
          // 如果不符合手机号正则
          if (!reg.test(value)) {
            // 进一步判断是否符合邮箱正则
            const reg2 = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
            if (!reg2.test(value)) {
              callback(new Error('手机号/邮箱格式错误'))
            }
          }
        }
        // 满足手机号或邮箱任一
        callback()
      }
    }
    // 验证图形验证码是否一致
    const validateVerifycode = (rule, value, callback) => {
      // 小写转换
      const newVal = value.toLowerCase()
      const identifyStr = this.identifyCode.toLowerCase()
      if (newVal === '') {
        callback(new Error('请输入图形验证码'))
      } else if (newVal !== identifyStr) {
        callback(new Error('图形验证码不正确'))
      } else {
        callback()
      }
    }
    return {
      // 登录按钮
      isLogin: 0,
      // 实名认证提示
      dialogVisible: false,
      // 是否记住账号和密码
      remember: false,
      // 判断登录状态
      isShow: false,
      // 是否显示本面板
      modelShow: false,
      // 是否可以关闭
      canClose: false,
      // 当前生成的验证码
      identifyCode: '',
      loginForm: {
        account: '',
        password: '',
        verification: '',
        check: false
      },
      rules: {
        account: [
          { required: true, message: '请输入账号', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 18, message: '长度在6到18个字符', trigger: 'blur' }
        ],
        verification: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { validator: validateVerifycode, message: '验证码不正确', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    if (sessionStorage.getItem('checked')) {
      this.isLogin = sessionStorage.getItem('checked')
    }
  },
  computed: {
    // 都不为空时btnState==true，改变按钮禁用状态
    btnState () {
      return this.loginForm.account !== '' && this.loginForm.password !== '' && this.loginForm.verification !== '' && this.loginForm.check
    }
  },
  mounted () {
    // 渲染记住的账号密码
    const account = localStorage.getItem('account')
    if (account) {
      this.loginForm.account = localStorage.getItem('account')
      this.loginForm.password = Base64.decode(localStorage.getItem('password'))
      this.remember = true
    }
  },
  methods: {
    toAdmin () {
      this.$refs.login.validate(valid => {
        if (valid) {
          // 账号密码的存储
          sessionStorage.setItem('password', Base64.encode(this.loginForm.password))
          if (this.remember) {
            localStorage.setItem('account', this.loginForm.account)
            localStorage.setItem('password', Base64.encode(this.loginForm.password))
          } else {
            localStorage.removeItem('account')
            localStorage.removeItem('password')
          }
          const that = this
          const data = {
            account: this.loginForm.account,
            password: this.loginForm.password,
            pid: sessionStorage.getItem('pid')
          }
          // 当前网址
          const url = document.location.href
          // console.log(data)
          this.$apiFun.userLogin(data).then(res => {
            if (res.code == 1) {
              // console.log(res)
              sessionStorage.setItem('indet', res.data.userinfo.certificationType)
              that.$store.commit('setToken', res.data.userinfo.token)
              this.$notify({
                title: '登录成功',
                type: 'success',
                showClose: false,
                duration: 1000
              })
              document.location.href = url
              this.$emit('closeDialog')
              this.isShow = false
              this.$bus.$emit('isShow', this.isShow)
              if (res.data.userinfo.certificationType == 0) {
                // this.$message.warning('您还未实名认证，即将跳转到实名界面')
                setTimeout(() => {
                  this.$confirm('您还未实名认证，是否跳转到实名界面?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                  }).then(() => {
                    this.$router.push('/admin/ident')
                  }).catch(() => {
                    this.$message.info('根据最新规定，以及维护社会网络安全，请您及时进行实名认证')
                  })
                }, 1000)
              }
            } else {
              this.$message.warning(res.msg)
            }
          }).catch(error => {
            this.$message.warning('账号或密码错误！')
          })
        } else {
          this.$message.error('请输入正确的信息')
          // console.log('error submit!!')
          this.isShow = true
          this.$bus.$emit('isShow', this.isShow)
          return false
        }
      })
    },
    // 重置表单
    resetForm () {
      this.$refs.login.resetFields()
    }
  }
}
</script>

<style scoped>
.blue {
  color: #55aaff;
}

::v-deep .el-checkbox__label {
  white-space: pre-line;
  word-wrap: break-word;
}</style>
