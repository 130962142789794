<template>
  <div>
    <el-button type="primary" @click="toCode()" style="margin-left: 10px;" :disabled="disable">
      <span v-if="countFlag">获取验证码</span>
      <span v-else>剩余{{countNum}}S</span>
    </el-button>
  </div>
</template>

<script>
export default {
  props: ['phone', 'event'],
  data () {
    return {
      countNum: 60,  // 倒计时秒数
      countFlag: true,
      disable: false
    }
  },
  methods: {
    // 监听关闭页面之后重新打开是否继续倒计时
    monitor () {
      let LocalDelay = this.getLocalDelay()
      let timeLine = parseInt((new Date().getTime() - LocalDelay.time) / 1000)
      if (timeLine > LocalDelay.delay) {
        console.warn('倒计时过期')
      } else {
        this.countFlag = false
        this.disable = true
        this.countNum = LocalDelay.delay - timeLine
        let timer = setInterval(() => {
          if (this.countNum > 1) {
            this.countNum--
            // 设置每一次还需倒计时
            this.setLocalDelay(this.countNum)
          } else {
            clearInterval(timer)
            this.countFlag = true
            this.disable = false
          }
        }, 1000)
      }
    },
    countDown () {
      if (this.countFlag) {
        this.countFlag = false
        this.disable = true
        this.countNum = 120
        let timer = setInterval(() => {
          if (this.countNum > 1) {
            this.countNum--
            this.setLocalDelay(this.countNum)
          } else {
            clearInterval(timer)
            this.countFlag = true
            this.disable = false
          }
        }, 1000)
      } else {
        return false
      }
    },
    setLocalDelay (delay) {
      // location.href作为页面唯一标识符，可能一个项目中会有多个验证码
      localStorage.setItem('delay_' + location.href, delay)
      localStorage.setItem('time_' + location.href, new Date().getTime())
    },
    getLocalDelay () {
      let LocalDelay = {
        delay: localStorage.getItem('delay_' + location.href),
        time: localStorage.getItem('time_' + location.href)
      }
      return LocalDelay
    },
    toCode () {
      if (this.phone == '') {
        this.$message.error('请输入手机号')
      } else {
        let data = {
          mobile: this.phone,
          event: this.event
        }
        this.$apiFun.getCode(data).then(res => {
          // console.log(res)
          if (res.code == 1) {
            this.countDown()
            this.$message.success('短信验证码发送成功')
          } else {
            this.$message.error(res.msg)
          }
        })
      }
    }
  }
}
</script>

<style>
</style>
