// api统一接口
// 导入封装好的全局网络
import { post, get } from './index'

const apiFun = []

// 1 注册/api/user/register
apiFun.register = p => post('/api/user/register', p)

// 2 验证用户名是否存在 /api/user/VerifyUserName
apiFun.VerifyUserName = p => get('/api/user/VerifyUserName', p)

// 3 获取验证码/api/sms/send
apiFun.getCode = p => post('/api/sms/send', p)

// 4 获取网站信息/api/website/getWebsiteConfig
apiFun.getWebsiteConfig = p => post('/api/website/getWebsiteConfig', p)

// 5 登录
apiFun.userLogin = p => post('/api/user/login', p)

// 6 退出登录 /api/user/logout
apiFun.logout = p => post('/api/user/logout', p)

// 7 修改密码/api/user/resetpwd
apiFun.resetpwd = p => post('/api/user/resetpwd', p)

// 8 按次方案 /api/shop/getScoreCombo
apiFun.getScoreCombo = p => get('/api/shop/getScoreCombo', p)
// 9 按次价格 /api/shop/ScoreCheckprice
apiFun.ScoreCheckprice = p => post('/api/shop/ScoreCheckprice', p)
// 10 按次订单 /api/pay/createScoreOrder
apiFun.createScoreOrder = p => post('/api/pay/createScoreOrder', p)

// 11 按量方案 /api/shop/getTimePacketCombo
apiFun.getTimePacketCombo = p => get('/api/shop/getTimePacketCombo', p)
// 12 按量价格 /api/shop/TimePacketCheckprice
apiFun.TimePacketCheckprice = p => post('/api/shop/TimePacketCheckprice', p)
// 13 按量订单 /api/pay/createScoreOrder
apiFun.createTimepacketOrder = p => post('/api/pay/createTimepacketOrder', p)

// 14 不限量方案 /api/shop/getUnlimitedQtyCombo
apiFun.getUnlimitedQtyCombo = p => post('/api/shop/getUnlimitedQtyCombo', p)
// 15 不限量价格/api/shop/UnlimitedQtyCheckprice
apiFun.UnlimitedQtyCheckprice = p => post('/api/shop/UnlimitedQtyCheckprice', p)
// 16 不限量订单 /api/pay/createUnlimitedQtyOrder
apiFun.createUnlimitedQtyOrder = p => post('/api/pay/createUnlimitedQtyOrder', p)

// 17 获取订单状态 /api/pay/getOrderStatus
apiFun.getOrderStatus = p => post('/api/pay/getOrderStatus', p)

// 18 获取地域信息 /api/proxy_user/getAllRegionInfoList
apiFun.getAllRegionInfoList = p => post('/api/proxy_user/getAllRegionInfoList', p)

// 19 获取用户有效套餐 /api/proxy_user/getUserPackageCombo
apiFun.getUserPackageCombo = p => post('/api/proxy_user/getUserPackageCombo', p)

// 20 ip提取 /api/proxy_user/getIP
apiFun.getIP = p => post('/api/proxy_user/getIP', p)

// 21 获取新闻资讯 /api/help/news
apiFun.getNewsList = p => post('/api/help/news', p)

// 22 获取用户个人信息 /api/user/getUserInfo
apiFun.getUserInfo = p => post('/api/user/getUserInfo', p)

// 23 修改个人信息
apiFun.editUserInfo = p => post('/api/user/profile', p)

// 修改手机号 /api/user/changemobile
apiFun.changemobile = p => post('/api/user/changemobile', p)

// 获取用户订单
apiFun.userOrder = p => post('/api/pay/userOrder', p)

// 获取用户白名单
apiFun.getUserWhiteIp = p => post('/api/proxy_user/getUserWhiteIp', p)

// 添加用户白名单
apiFun.addUserWhiteIp = p => post('/api/proxy_user/addUserWhiteIp', p)

// 删除用户白名单
apiFun.delUserWhiteIp = p => post('/api/proxy_user/delUserWhiteIp', p)

// 29 获取用户使用记录
apiFun.getUserIpUseLog = p => post('/api/proxy_user/getUserIpUseLog', p)

// 百度回传数据
apiFun.ocpcSendData = p => post('/api/Bdocpc/uploadConvertData', p)

export default apiFun
