<template>
  <el-dialog :visible.sync='dialogVisible' :lock-scroll="false" :modal-append-to-body='false' v-if="dialogVisible">
    <div class="sub-main-w3">
      <div class="vertical-tab">
        <!-- 登录 -->
        <LoginPart @closeDialog='closeDialog'></LoginPart>
        <!-- 注册 -->
        <RegisterPart @closeDialog='closeDialog'></RegisterPart>
        <!-- 重置密码 -->
        <ResetPart @closeDialog='closeDialog'></ResetPart>
      </div>
      <div class="clear"></div>
    </div>
  </el-dialog>
</template>

<script>
import LoginPart from '@/components/dialog/loginPart'
import RegisterPart from '@/components/dialog/registerPart'
import ResetPart from '@/components/dialog/resetPart'

export default {
  components: {
    LoginPart,
    RegisterPart,
    ResetPart
  },
  data () {
    return {
      dialogVisible: false
    }
  },
  methods: {
    // 弹框
    openDialog () {
      this.dialogVisible = true
    },
    closeDialog () {
      this.dialogVisible = false
    }
  }
}
</script>

<style scoped>
@import '@/assets/css/style.css';
</style>
