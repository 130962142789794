<template>
  <div>
    <div id="ad_bg" class="ad" href="/price">
      <a href="/price"><img :src="banner" alt="" style="height: 60px;"></a>
      <i class="el-icon-close close" @click.stop="toClose"></i>
    </div>
    <header id="active-sticky" class="header-section">
      <div class="container">
        <div class="align-items-center topall">
          <div class="col">
            <div class="header-logo">
              <a href="/"><img :src="logo" alt="Site Logo" style="height: 58px; width: 136px;"></a>
            </div>
          </div>
          <div class="col">
            <nav class="main-menu d-none d-lg-block">
              <el-menu :default-active="$route.path" active-text-color='rgba(11, 124, 245, 1)'
                text-color='rgba(56, 56, 56, 1)' class="el-menu-demo" mode="horizontal" :router="true">
                <el-menu-item index="/">首页</el-menu-item>
                <el-submenu index="2">
                  <template slot="title">产品</template>
                  <el-menu-item index="/short-term">短效优质代理</el-menu-item>
                  <el-menu-item index="/long-term">长效代理</el-menu-item>
                </el-submenu>
                <el-menu-item index="/price">套餐购买</el-menu-item>
                <el-menu-item index="/about">API提取</el-menu-item>
                <el-menu-item index="/help">帮助指南</el-menu-item>
              </el-menu>
              <!-- <ul class="d-flex">
              <li><a href="/">首页</a></li>
              <li>
                <a href="javascript:void(0)">产品<i class="el-icon-caret-bottom"></i></a>
                <ul class="sub-menu">
                  <li class="sub-menu-item">
                    <a class="sub-menu-link" href="/short-term">短效优质代理</a>
                  </li>
                  <li class="sub-menu-item">
                    <a class="sub-menu-link" href="/long-term">长效代理</a>
                  </li>
                </ul>
              </li>
              <li><a href="/price">套餐购买</a></li>
              <li><a href="/about">API提取</a></li>
              <li><a href="/help">帮助指南</a></li>
            </ul> -->
            </nav>
            <!-- 小屏时打开抽屉组件 -->
            <button class="toggle" @click="drawer = true">
              <span class="icon-top"></span>
              <span class="icon-middle"></span>
              <span class="icon-bottom"></span>
            </button>
            <!-- 抽屉组件 -->
            <el-drawer :visible.sync="drawer" :modal='false'>
              <template slot="title">
                <img :src="logo" alt="logo" style="width: 50px;">
              </template>
              <nav id="offcanvasNav" class="offcanvas-menu">
                <ul>
                  <li><a href="/">首页</a></li>
                  <li>
                    <a href="javascript:void(0)" @click="isOpen">产品</a>
                    <ul>
                      <li><a href="/short-term">短效优质代理</a></li>
                      <li><a href="/long-term">长效代理</a></li>
                    </ul>
                  </li>
                  <li><a href="/price">套餐购买</a></li>
                  <li><a href="/about">API提取</a></li>
                  <li><a href="/help">帮助指南</a></li>
                </ul>
              </nav>
            </el-drawer>
          </div>
          <!-- 登录登出按钮 -->
          <div class='col s-right'>
            <div v-if="isShow">
              <el-button @click="toLogin(0)" class="login"> 登 录 </el-button>
              <el-button class="elbutton2" @click="toLogin(1)" plain type="primary"> 注册免费试用 </el-button>
            </div>
            <div v-else>
              <el-button @click="toadmin" type="primary"> 会 员 中 心 </el-button>
              <el-button type="danger" @click="loginout()"> 退 出 </el-button>
            </div>
          </div>
        </div>
      </div>
      <!-- 登录注册弹窗 -->
      <loginDialog ref="login"></loginDialog>
    </header>
  </div>
</template>

<script>
import loginDialog from '@/components/loginView'

export default {
  components: {
    loginDialog
  },
  data () {
    return {
      banner: '',
      logo: require('@/assets/img/s/327a9be7214f1542442997cb63ecc791.png'),
      // 判断是否登录
      hide: true,
      isShow: true,
      // 抽屉组件
      drawer: false,
      // 判断是否添加类名
      flag: false
    }
  },
  created () {
    this.$bus.$on('isShow', (val) => {
      this.isShow = val
    })
    // console.log(1, window.sessionStorage.getItem('token'))
    if (window.sessionStorage.getItem('token')) {
      this.isShow = false
    } else {
      this.isShow = true
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll, true)
    window.addEventListener('setItem', () => {
      this.logo = sessionStorage.getItem('logo')
    })
    this.banner = window.sessionStorage.getItem('banner')
  },
  methods: {
    // 滚动后改变顶部导航样式
    handleScroll () {
      const scrolltop = document.documentElement.scrollTop || document.body.scrollTop
      if (scrolltop > 30) {
        document.getElementById('active-sticky').classList.add('is-sticky')
        if (this.banner) {
          document.getElementById('active-sticky').style.top = '60px'
        } else {
          document.getElementById('active-sticky').style.top = '0px'
        }
        // document.getElementById('active-sticky').style.paddingTop = '15px'
      } else {
        document.getElementById('active-sticky').classList.remove('is-sticky')
        // document.getElementById('active-sticky').style.paddingTop = '60px'
        if (this.banner) {
          document.getElementById('active-sticky').style.top = '0px'
        }
      }
    },
    toClose (e) {
      this.banner = ''
      // console.log(document.getElementsByClassName('active-sticky'))
      // document.getElementById('ad_bg').classList.add('ad_bg')
      // document.getElementById('active-sticky').style.top = '0px'
      // document.getElementById('active-sticky').style.paddingTop = '60px'
    },
    // 展开当前li下面的内容
    isOpen (e) {
      if (this.flag) {
        e.currentTarget.parentElement.classList.remove('active')
        this.flag = false // 判断是否添加类名
      } else {
        e.currentTarget.parentElement.classList.add('active')
        this.flag = true
      }
    },
    // 关闭弹窗
    toLogin (e) {
      // 检查当前域名是否包含 'ip.dataip.com.cn'
      if (window.location.hostname.includes('ip.dataip.com.cn')) {
        window.location.href = 'https://www.kuainiaoip.com';
        return; // 立即跳转，停止后续操作
      }
      this.$refs.login && this.$refs.login.openDialog()
      console.log(e)
      sessionStorage.setItem('checked', e)
    },
    // 登录后跳转到后台
    toadmin () {
      this.$router.push('/admin')
    },
    // 退出登录
    loginout () {
      this.$confirm('此操作将登出当前账号, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$apiFun.logout().then(res => {
          if (res.code == 1) {
            sessionStorage.removeItem('token')
            this.isShow = true
            this.$message.success('登出成功!')
            location.reload()
          }
        })
      }).catch(() => {
        this.isShow = false
        this.$message({
          type: 'info',
          message: '已取消登出'
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .container {
  .topall {
    .header-logo {
      padding-bottom: 1rem;
    }

    .main-menu {
      .el-menu-demo {
        .el-menu-item {
          height: 3.5rem;
        }
      }
    }

    .s-right {
      .login {
        width: 5.75rem;
        height: 1.875rem;
        border-radius: .25rem;
        border: 1px solid rgba(11, 124, 245, 1);
        padding: 0;

        span {
          font-size: 1rem;
          font-weight: 500;
          color: rgba(11, 124, 245, 1);
        }
      }

      .elbutton2 {
        width: 8.25rem;
        height: 2rem;
        padding: 0;
        opacity: 1;
        border-radius: .25rem;
        background: linear-gradient(90deg, rgba(11, 124, 245, 1) 0%, rgba(8, 201, 143, 1) 100%);

        span {
          font-size: 1rem;
          font-weight: 500;
          color: rgba(255, 255, 255, 1);
        }
      }
    }
  }
}
</style>

<style scoped >
.header-section {
  width: 120rem;
  padding: 16px 0 0 0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.topall {
  display: flex;
  justify-content: space-between;
}

.is-show {
  opacity: 0;
}

.login {
  border-color: #409EFF;
  color: #409EFF;
}

::v-deep .el-button--primary.is-plain {
  background: linear-gradient(45deg, rgba(11, 124, 245, 1), rgba(8, 201, 143, 1));
  color: #fff;
}

.ad {
  height: 60px;
  width: 100%;
  /* background-color: cadetblue; */
  z-index: 999;
  position: fixed;
  top: 0;
}

.ad_bg {
  display: none;
}

.close {
  padding: 4px;
  background-color: #efefef11;
  color: #fff;
  font-weight: bold;
  position: absolute;
  right: 20px;
  top: 18px;
  cursor: pointer;
  z-index: 9999;
}

::v-deep .el-menu {
  background-color: transparent;
}

::v-deep .el-menu--horizontal>.el-menu-item {
  font-size: 16px;
  font-weight: 500;
  padding: 0;
  margin: 0 15px;
}

::v-deep .el-submenu__title {
  font-size: 16px;
  font-weight: 500;
  padding: 0;
  margin: 0 15px;
}

::v-deep .el-menu--horizontal>.el-menu-item:not(.is-disabled):hover {
  background-color: transparent;
}

::v-deep .el-menu.el-menu--horizontal {
  border: none;
}
</style>
