import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   redirect: '/'
  // },
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/HomeView.vue')
  },
  {
    path: '/agent',
    name: 'agent',
    component: () => import('@/views/AgentView.vue'),
    meta: {
      hideHeader: true,
      hideFooder: true
    }
  },
  {
    path: '/short-term',
    name: 'short-term',
    component: () => import('@/views/ShortTermView')
  },
  {
    path: '/long-term',
    name: 'long-term',
    component: () => import('@/views/LongTermView')
  },
  // {
  //   path: '/price1',
  //   name: 'price1',
  //   component: () => import('@/views/PriceView1')
  // },
  {
    path: '/price',
    name: 'price',
    component: () => import('@/views/PriceView')
  },
  {
    path: '/help',
    name: 'help',
    component: () => import('@/views/HelpView.vue')
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import('@/views/help/infoDetail.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/AboutView.vue')
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('@/views/TestView.vue')
  },
  {
    path: '/policy',
    name: 'policy',
    component: () => import('@/views/PrivacyPolicy.vue')
  },
  {
    path: '/service',
    name: 'service',
    component: () => import('@/views/ServiceAgreement.vue')
  },
  {
    path: '/admin',
    // name: 'admin',
    component: () => import('@/views/AdminView.vue'),
    meta: {
      hideHeader: true,
      hideFooder: true,
      // 进入页面时判断是否登录
      requireAuth: true
    },
    children: [
      {
        path: '/',
        redirect: 'dashboard'
      },
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () => import('@/views/admin/Dashboard.vue'),
        meta: {
          hideHeader: true,
          hideFooder: true,
          // 进入页面时判断是否登录
          requireAuth: true
        }
      },
      {
        path: 'notice',
        name: 'notice',
        component: () => import('@/views/admin/noticePart.vue'),
        meta: {
          hideHeader: true,
          hideFooder: true
        }
      },
      {
        path: 'info',
        name: 'info',
        component: () => import('@/views/admin/infoPart.vue'),
        meta: {
          hideHeader: true,
          hideFooder: true
        }
      },
      {
        path: 'order',
        name: 'order',
        component: () => import('@/views/admin/OrderList.vue'),
        meta: {
          hideHeader: true,
          hideFooder: true
        }
      },
      {
        path: 'manage',
        name: 'manage',
        component: () => import('@/views/admin/managePart.vue'),
        meta: {
          hideHeader: true,
          hideFooder: true
        }
      },
      {
        path: 'White-list',
        name: 'White-list',
        component: () => import('@/views/admin/WhiteListPart.vue'),
        meta: {
          hideHeader: true,
          hideFooder: true
        }
      },
      {
        path: 'record',
        name: 'record',
        component: () => import('@/views/admin/recordPart.vue'),
        meta: {
          hideHeader: true,
          hideFooder: true
        }
      },
      {
        path: 'ident',
        name: 'ident',
        component: () => import('@/views/admin/identPart.vue'),
        meta: {
          hideHeader: true,
          hideFooder: true
        }
      }
    ]
  },
  {
    path: '*',
    name: 'notFound',
    component: () => import('@/views/admin/404Part.vue'),
    meta: {
      hideHeader: true,
      hideFooder: true
    }
  }
]

// router.beforeEach(async (to, from, next) => {
//     if (to.path) {
//        if (window._hmt) {
//             window._hmt.push(['_trackPageview', '/#' + to.fullPath]);
//         }
//     }
//     next();
// });

const scrollBehavior = (to, from, savedPosition) => {
  // vue2.0  x  y  控制
  // vue3.0  left  top 控制
  // return { left: 0, top: 0 }
  return { x: 0, y: 0 }
}

const router = new VueRouter({
  routes,
  mode: 'history',
  // 解决vue页面之间跳转，页面不是在顶部的问题
  scrollBehavior
})

const routerMethods = ['push', 'replace']
routerMethods.forEach(method => {
  const originalCall = VueRouter.prototype[method]
  VueRouter.prototype[method] = function (location, onResolve, onReject) {
    if (onResolve || onReject) {
      return originalCall.call(this, location, onResolve, onReject)
    }
    return originalCall.call(this, location).catch(err => err)
  }
})

export default router
