<template>
  <div id="section2" class="section-w3ls">
    <input type="radio" name="sections" id="option2" :checked="isRegis==1">
    <label for="option2" class="icon-left-w3pvt">
      <span class="fa fa-pencil-square" aria-hidden="true"></span>注册
    </label>
    <article>
      <el-form style="text-align: center;" :model="registerForm" ref="register" :rules="rules">
        <el-form-item>
          <h3 class="legend">在此注册</h3>
        </el-form-item>
        <el-form-item prop="accountName">
          <el-input auto-complete='new-password' v-model="registerForm.accountName" prefix-icon="el-icon-user" placeholder="账号名(字母、数字、-，必须以字母开头)"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input auto-complete='new-password' v-model="registerForm.password" prefix-icon="el-icon-key" show-password placeholder="密码"></el-input>
        </el-form-item>
        <el-form-item prop="phoneNumber">
          <el-input auto-complete='new-password' v-model="registerForm.phoneNumber" prefix-icon="el-icon-mobile-phone" placeholder="手机号"></el-input>
        </el-form-item>
        <el-form-item prop="verification">
          <div style="display: flex;justify-content: space-between;">
            <el-input auto-complete='new-password' v-model="registerForm.verification" prefix-icon='el-icon-folder-checked' placeholder='验证码'></el-input>
            <valid style="margin-left: 10px;" :phone='this.registerForm.phoneNumber' :event='event'></valid>
          </div>
        </el-form-item>
        <el-form-item>
          <el-checkbox v-model="registerForm.isCheck">我已阅读并同意相关<a href="/service" class="blue">服务条款</a>和<a href="/policy" class="blue">隐私政策</a></el-checkbox>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" style="width: 100%;" :disabled="btnState==false" @click="toRegister" data-agl-cvt='5' v-preventReClick> 注 册 </el-button>
        </el-form-item>
      </el-form>
    </article>
  </div>
</template>

<script>
import valid from '@/components/getCode.vue'
import axios from 'axios'

export default {
  components: {
    valid
  },
  data () {
    const checkName = (rule, value, callback) => {
      if (value != '') {
        if (this.veriname()) {
          callback(new Error('用户名已存在，请您重新输入'))
        } else {
          callback()
        }
      }
      callback()
    }
    return {
      isRegis: 0,
      event: 'register',
      registerForm: {
        accountName: '',
        phoneNumber: '',
        password: '',
        verification: '',
        isCheck: false
      },
      rules: {
        accountName: [
          { required: true, message: '账号名不可为空', trigger: 'blur' },
          { pattern: /^[a-zA-Z][-a-zA-Z0-9]*$/, message: '账号名格式不正确', trigger: 'blur' },
          { validator: checkName, trigger: 'blur' }
        ],
        phoneNumber: [
          { required: true, message: '手机号不可为空', trigger: 'blur' },
          { pattern: /^1[3-9]\d{9}$/, message: '手机号格式不正确', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '新密码不能为空', trigger: 'blur' },
          { min: 6, max: 18, message: '用户密码的长度在6～18个字符', trigger: 'blur' }
        ],
        verification: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { len: 4, message: '验证码格式错误', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    if (sessionStorage.getItem('checked')) {
      this.isRegis = sessionStorage.getItem('checked')
    }
  },
  computed: {
    // 不为空时btnState==true，改变按钮禁用状态
    btnState () {
      return this.registerForm.accountName !== '' && this.registerForm.phoneNumber !== '' && this.registerForm.password !== '' && this.registerForm.verification && this.registerForm.isCheck
    }
  },
  methods: {
    veriname () {
      if (this.registerForm.accountName) {
        const data = {
          username: this.registerForm.accountName
        }
        this.$apiFun.VerifyUserName(data).then(res => {
          if (res.code == 1) {
            return false
          } else {
            this.$message.error('用户名已存在,请您重新输入')
            this.registerForm.accountName = ''
          }
        })
      }
    },
    toRegister () {
      this.$refs.register.validate(valid => {
        if (valid) {
          const data = {
            username: this.registerForm.accountName,
            password: this.registerForm.password,
            mobile: this.registerForm.phoneNumber,
            code: this.registerForm.verification,
            pid: sessionStorage.getItem('pid')
          }
          this.$apiFun.register(data).then(res => {
            // console.log(res)
            if (res.code == 1) {
              this.$message.success('注册成功，请在登录页面进行登录')
              this.$emit('closeDialog')
              window._agl && window._agl.push(['track', ['success', { t: 3 }]])
              // 注册成功，向百度回传数据
              this.ocpcSendData('', 25)
              this.$message.info(res.msg)
            } else {
              // this.ocpcSendData('', 25)
              this.$message.info(res.msg)
            }
          })
        } else {
          this.$message.error('注册失败')
          return false
        }
      })
    },
    // 百度回传
    ocpcSendData (logidUrl, newType) {
      const url = window.location.href
      console.log('============', url)
      console.log(url.indexOf('bd_vid'))
      const bd_vid = url.indexOf('bd_vid')
      const postData = {
        logidUrl: url,
        newType: newType
      }
      // 若存在百度id
      if (bd_vid !== -1) {
        this.$apiFun.ocpcSendData(postData).then(res => {
          if (res.status == 0) {
            console.log('回传成功')
          } else {
            console.log(res.msg)
          }
        }).catch(err => {
          console.log(err)
        })
      }
    }
  }
}
</script>

<style scoped>
  .blue {
    color: #55aaff;
  }
  ::v-deep .el-checkbox__label {
    white-space: pre-line;
    word-wrap: break-word;
  }
</style>
