import Vue from 'vue'
import Vuex from 'vuex'
import apiFun from '../request/api'


Vue.use(Vuex)

// 用vuex.store对象用来记录token
const key = 'token'
const store = new Vuex.Store({
  state() {
    return {
      token: sessionStorage.getItem('token') || '',
      userName: '',
      logo: require('@/assets/img/s/327a9be7214f1542442997cb63ecc791.png'),
      banner: '',
      pid: '',
      name: '',
      site: '',
      kfimg: '',
      gzhimg: '',
      city: '',
      address: '',
      mobile: '',
      baserurl: 'https://api.kuainiaoip.com',
    }
  },
  getters: {
    getToken(state) {
      if (!state.token) {
        state.token = sessionStorage.getItem('token')
      }
      return state.token
    }
  },
  mutations: {
    // 修改token，并将token存入localStoreage
    setToken(state, token) {
      state.token = token
      sessionStorage.setItem(key, token)
      // console.log('store/sessionStorage保存token成功')
    },
    delToken(state) {
      state.token = ''
      sessionStorage.removeItem(key)
    },
    setSiteInfo(state, siteInfo) {
      Object.assign(state, siteInfo);
    }
  },
  actions: {
    // removeToken: (context) => {
    //   context.commit('setToken')
    // }

    async getSiteInfo({ commit, state }) {
      const data = {
        domainname: window.location.hostname
      };
      try {
        const res = await apiFun.getWebsiteConfig(data);
        if (res.code === 1) {
          const siteInfo = {
            logo: state.baserurl + res.data.logo,
            banner: res.data.banner ? state.baserurl + res.data.banner : '',
            pid: res.data.pid,
            name: res.data.name,
            site: res.data.domainname.split('//')[1].split('ip.com')[0],
            kfimg: state.baserurl + res.data.workweixin,
            gzhimg: state.baserurl + res.data.mpeixin,
            city: res.data.city,
            address: res.data.city.replace('/', '').replace('/', '') + res.data.address,
            mobile: res.data.mobile
          };

          // 提交 mutation 更新状态
          commit('setSiteInfo', siteInfo);

          // 存储到 sessionStorage
          sessionStorage.setItem('logo', siteInfo.logo);
          sessionStorage.setItem('banner', siteInfo.banner);
          sessionStorage.setItem('pid', siteInfo.pid);
          sessionStorage.setItem('name', siteInfo.name);
          sessionStorage.setItem('site', siteInfo.site);
        } else {
          this.$message.info(res.msg);
        }
      } catch (error) {
        console.error(error);
        this.$message.error('获取网站信息失败');
      }
    }

  }
})

export default store
